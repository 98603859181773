import React from "react";
import { Routes, Route } from "react-router-dom";
import ForgotPassword from "../pages/ForgotPassword";
import NotFound from "../pages/NotFound";
import ProtectedRoute from "./ProtectedRoute";
import CreatePassword from "../pages/CreatePassword";
import ResetPassword from "../pages/ResetPassword";
import DefaultLayout from "../components/DefaultLayout";
import CustomerForm from "../customerQRForm/CustomerForm";
import { ErrorBoundary } from "react-error-boundary";
const Routing = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ErrorBoundary
            fallback={
              <div className="error-boundary">
                <h1>Something went wrong!</h1>
              </div>
            }
          >
            <ProtectedRoute route="LandingPage" />
          </ErrorBoundary>
        }
      />
      <Route
        path="/sign-in"
        element={
          <ErrorBoundary
            fallback={
              <div className="error-boundary">
                <h1>Something went wrong!</h1>
              </div>
            }
          >
            <ProtectedRoute route="SignIn" />
          </ErrorBoundary>
        }
      />
      <Route
        path="/create-password"
        element={
          <ErrorBoundary
            fallback={
              <div className="error-boundary">
                <h1>Something went wrong!</h1>
              </div>
            }
          >
            <CreatePassword />
          </ErrorBoundary>
        }
      />
      <Route
        path="/reset-password"
        element={
          <ErrorBoundary
            fallback={
              <div className="error-boundary">
                <h1>Something went wrong!</h1>
              </div>
            }
          >
            <ResetPassword />
          </ErrorBoundary>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <ErrorBoundary
            fallback={
              <div className="error-boundary">
                <h1>Something went wrong!</h1>
              </div>
            }
          >
            <ForgotPassword />
          </ErrorBoundary>
        }
      />
      <Route
        path="/customer-form"
        element={
          <ErrorBoundary
            fallback={
              <div className="error-boundary">
                <h1>Something went wrong!</h1>
              </div>
            }
          >
            <CustomerForm />
          </ErrorBoundary>
        }
      />
      <Route
        path="/agent-dashboard"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="AgentDashboard" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/merchant-dashboard"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="MerchantDashboard" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/dashboard"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="Dashboard" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/employeeManagement/add-employee"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="AddEmployee" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/employeeManagement/add-employee-role"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="AddEmpRole" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/employee-permission"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="EmployeePermission" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/add-pos"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="AddPos" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/all-pinpad-list"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="AllPinPadList" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/upload-pos-apk"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="UploadPosApk" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/storemanagement/pos-customize"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="PosCustomize" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/storemanagement/ip-config"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="IpConfiguration" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/storemanagement/registeredPos-list"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="PosListStoreWise" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/storemanagement/add-flag"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="AddFlag" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/add-store"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="AddStore" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/storemanagement/add-department"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="AddDepartment" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/storemanagement/add-product"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="Products" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/storemanagement/add-promotion"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="PromotionManagement" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/storemanagement/tax-mangement"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="TaxMangement" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/storemanagement/card-fee-setup"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="CardFeeSetup" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/storemanagement/mass-product-update"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="MassProductUpdate" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/storemanagement/purchase-order"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="PurchaseOrder" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/storemanagement/purchase-order/order-history"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="OrderHistory" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/storemanagement/purchase-order/preview-order"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="PreviewOrder" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/storemanagement/store-setting"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="StoreSetting" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/report/product"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="ProductReport" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/report/pay-roll"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="PayRollReport" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/report/productivity"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="ProductivityReport" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/report/shift-report"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="ShiftReport" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/report/sales-report"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="SalesReport" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/report/department"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="DepartmentReport" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/report/tax-report"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="TaxReport" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/report/pay-roll"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="Reports" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/report/clock-in-out"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="ClockInOutReport" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/report/clock-in-out/details"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="ClockInOutDetailsByDay" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/report/clock-in-out/byDateRange"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="ClockInOutByRange" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/report/transaction"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="TransactionReport" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/report/transaction/details/:id/:orderStatus"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="TransactionDetails" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/report/batch-report"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="BatchReport" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/report/batch-report/details/:id"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="BatchDetails" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/report/cona-report"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="ConaReport" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/report/emp-productivity"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="ProductivityReport" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/user-management"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="UserManagement" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/customer-engagement"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="CustomerEngagement" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/manage-agent"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="ManageAgent" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/manage-merchant"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="ManageMerchant" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/store-list"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="StoreList" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/usersetting"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="UserSetting" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/contact-us"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="ContactUs" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/customer-engagement/customer-detail/:id"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="CustomerDetail" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route
        path="/customer-engagement/manage-group"
        element={
          <DefaultLayout
            children={
              <ErrorBoundary
                fallback={
                  <div className="error-boundary">
                    <h1>Something went wrong!</h1>
                  </div>
                }
              >
                <ProtectedRoute route="CustomerGroup" />
              </ErrorBoundary>
            }
          />
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default Routing;
