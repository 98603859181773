import "../styles/DefaultLayout.css";
import loginLogo from "../assets/admin-logo.png";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CloseOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import RoleWiseSidebar from "./RoleWiseSidebar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getStoreIdAction,
  agentDataAction,
  storeDataAction,
  userDataAction,
  userRoleAction,
} from "../redux/actions/userAction";
import {
  merchantDataAction,
  purchaseOrderPlaceDataAction,
  statusOfOrderDataValue,
} from "../redux/actions/merchantAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { getReq, postReqwithoutHeader } from "../utils/constant";
import { getUserData, setUserData, setUserToken } from "../utils/utils";
import { useMediaQuery } from "react-responsive";
const DefaultLayout = (props) => {
  const { Header, Sider, Content } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const [showSide, setShowSide] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const userName = useSelector((store) => store.userReducer.userData);
  const storeRecord = useSelector((store) => store.userReducer.storeData);
  const storeID = useSelector((store) => store.userReducer.storeIdByMerchant);
  const role = useSelector((store) => store.userReducer.userRole);
  const superAdminData = useSelector(
    (store) => store.userReducer.superAdminData
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.clear();
    navigate("/sign-in");
  };
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const { pathname } = useLocation();

  useEffect(() => {
    if (isMobile) {
      setShowSide(true);
    }
    // eslint-disable-next-line
  }, [pathname]);

  const handleChange = (e) => {
    const id = storeRecord?.filter((data) => data._id === e.target.value);
    dispatch(getStoreIdAction(id[0]));
  };
  const getStoreList = async () => {
    const response = await getReq(`getAllStoreList`, navigate);
    const { result, status } = response.data;
    if (status === 200) {
      dispatch(storeDataAction(result));
    }
  };
  const getMerchantList = async () => {
    const apidata = JSON.parse(getUserData());
    const { _id } = apidata;
    const response = await getReq(
      `getMerchantList?createdById=${_id}`,
      navigate
    );
    const { result, status } = response.data;
    if (status === 200) {
      dispatch(merchantDataAction(result));
    }
  };
  const getAgentList = async () => {
    const response = await getReq(`getAgentList`, navigate);
    const { result, status } = response.data;
    if (status === 200) {
      dispatch(agentDataAction(result));
    }
  };
  const backToAdminLogin = async () => {
    const reqBody = {
      email: superAdminData.email,
      password: superAdminData.password,
    };
    const response = await postReqwithoutHeader(`web/login`, reqBody, navigate);
    const { token, result, status } = response.data;
    if (status === 200) {
      let { role } = result;
      setUserToken(token);
      setUserData(result);
      dispatch(userRoleAction(role));
      dispatch(userDataAction(result));
      navigate("/dashboard");
      dispatch(userRoleAction(role));
      dispatch(statusOfOrderDataValue(false));
      dispatch(purchaseOrderPlaceDataAction([]));
      getAgentList();
      getMerchantList();
      getStoreList();
    }
  };
  return (
    <Layout className={`${showSide ? "showSide" : ""}`}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className="leftSidebar"
      >
        <div className="text-center">
          <img
            src={loginLogo}
            alt="loginLogo"
            style={{
              width: "180px",
              margin: "10px auto 0",
              textAlign: "center",
            }}
          />
        </div>
        <div className="logo">
          <button
            className="remove-sidebar"
            onClick={() => setShowSide((preValue) => !preValue)}
          >
            <CloseOutlined />
          </button>
        </div>
        <RoleWiseSidebar role={role} />
      </Sider>
      <Layout className="site-layout">
        <Header className="topHeader">
          <button
            className="add-sidebar"
            onClick={() => setShowSide((preValue) => !preValue)}
          >
            <MenuOutlined />
          </button>
          <div className="side-trigger">
            <div>
              {role === "super-merchant" && (
                <button onClick={backToAdminLogin} className="edit-btn">
                  <i className="fa fa-backward" aria-hidden="true" />
                  <span>Back</span>
                </button>
              )}
            </div>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger custmTrigr",
                onClick: () =>
                  isMobile ? setCollapsed(false) : setCollapsed(!collapsed),
              }
            )}
          </div>
          {(role === "merchant" || role === "super-merchant") && (
            <div className="storeBox customStoreBox">
              <label className="storeNameLabel">Store Name</label>
              <select
                name="storeId"
                className="select-custom selectDrop"
                value={
                  storeRecord?.filter((item) => item?._id === storeID?._id)[0]
                    ?._id
                }
                onChange={handleChange}
                autoComplete="new-storeId"
              >
                {storeRecord && storeRecord?.length > 0 ? (
                  storeRecord?.map((item, index) => {
                    return (
                      <option value={item?._id} key={index}>
                        {item?.storeName}
                      </option>
                    );
                  })
                ) : (
                  <option value="">No Store Found!</option>
                )}
              </select>
            </div>
          )}
          <div className="userProfile">
            <div className="dropdown">
              <span
                className="dropdown-toggle custmDropdwon"
                type="button"
                onClick={() => setDropdownMenu(!dropdownMenu)}
              >
                <span className="iconDrdropdown">
                  <FontAwesomeIcon icon={faUserCircle} />
                </span>
                <span>
                  {userName?.firstName?.charAt(0).toUpperCase() +
                    userName?.firstName?.slice(1)}{" "}
                  {userName?.lastName?.charAt(0).toUpperCase() +
                    userName?.lastName?.slice(1)}
                </span>
              </span>
              <ul
                className={`${
                  dropdownMenu ? "show" : ""
                } dropdown-menu cstmdropdown-menu`}
              >
                <li key={1} onClick={() => setDropdownMenu(!dropdownMenu)}>
                  <Link className="dropdown-item" to="/usersetting">
                    Settings
                  </Link>
                </li>
                {(role === "merchant" || role === "super-merchant") && (
                  <li
                    onClick={() => {
                      setShowPin((prev) => !prev);
                    }}
                  >
                    <span>
                      <span className="dropdown-item">
                        PIN:
                        {showPin ? (
                          <span>{userName?.merchantPIN}</span>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="44"
                            height="44"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#2c3e50"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                            <path d="M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6" />
                          </svg>
                        )}
                      </span>
                    </span>
                  </li>
                )}
                <li key={2} onClick={() => setDropdownMenu(!dropdownMenu)}>
                  <button onClick={logOut} className="dropdown-item">
                    Logout
                  </button>
                </li>
                <div
                  className="cstm-overlay-dropdown"
                  onClick={() => setDropdownMenu(false)}
                ></div>
              </ul>
            </div>
          </div>
        </Header>
        <Content className="site-layout-background">{props?.children}</Content>
      </Layout>
    </Layout>
  );
};
export default DefaultLayout;
