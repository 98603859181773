import {
  AGENT_DATA,
  CASHIER_DATA,
  DELETE_RECORD_ROLE,
  STORE_DATA,
  STORE_ID,
  USER_DATA,
  USER_LOGIN,
  USER_ROLE,
  USER_TOKEN,
  SUPER_ADMIN_DATA,
  VIEW_VENDOR_ITEM_NUMBER,
} from "../constants";

const initialState = {
  userData: {},
  userToken: {},
  userRole: {},
  userLogin: {},
  agentData: {},
  recordRole: {},
  storeData: {},
  cashierData: {},
  posData: {},
  storeIdByMerchant: {},
  orderData: {},
  superAdminData: {},
  viewOrderReciept: {},
};

const userReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_DATA:
      return { ...state, userData: payload };
    case USER_TOKEN:
      return { ...state, userToken: payload };
    case USER_ROLE:
      return { ...state, userRole: payload };
    case USER_LOGIN:
      return { ...state, userLogin: payload };
    case SUPER_ADMIN_DATA:
      return { ...state, superAdminData: payload };
    case AGENT_DATA:
      return { ...state, agentData: payload };
    case STORE_DATA:
      return { ...state, storeData: payload };
    case STORE_ID:
      return { ...state, storeIdByMerchant: payload };
    case CASHIER_DATA:
      return { ...state, cashierData: payload };
    case DELETE_RECORD_ROLE:
      return { ...state, recordRole: payload };
    case VIEW_VENDOR_ITEM_NUMBER:
      return { ...state, viewOrderReciept: payload };
    default:
      return state;
  }
};
export default userReducer;
