import {
  AGENT_DATA,
  CASHIER_DATA,
  DELETE_RECORD_ROLE,
  STORE_DATA,
  STORE_ID,
  USER_DATA,
  USER_LOGIN,
  USER_ROLE,
  USER_TOKEN,
  SUPER_ADMIN_DATA,
  VIEW_VENDOR_ITEM_NUMBER,
} from "../constants";

export const userDataAction = (data) => {
  return {
    type: USER_DATA,
    payload: data,
  };
};
export const updateOrderRecieptAction = (data) => {
  return {
    type: VIEW_VENDOR_ITEM_NUMBER,
    payload: data,
  };
};
export const userTokenAction = (data) => {
  return {
    type: USER_TOKEN,
    payload: data,
  };
};
export const superAdminDataAction = (data) => {
  return {
    type: SUPER_ADMIN_DATA,
    payload: data,
  };
};
export const userRoleAction = (data) => {
  return {
    type: USER_ROLE,
    payload: data,
  };
};

export const userLogin = (data) => {
  return {
    type: USER_LOGIN,
    payload: data,
  };
};

export const agentDataAction = (data) => {
  return {
    type: AGENT_DATA,
    payload: data,
  };
};
export const storeDataAction = (data) => {
  return {
    type: STORE_DATA,
    payload: data,
  };
};
export const getStoreIdAction = (data) => {
  return {
    type: STORE_ID,
    payload: data,
  };
};
export const cashierDataAction = (data) => {
  return {
    type: CASHIER_DATA,
    payload: data,
  };
};
export const deleteRecordRole = (data) => {
  return {
    type: DELETE_RECORD_ROLE,
    payload: data,
  };
};
